@media (min-width: 768px) {
  body {
    margin: auto;
    width: 768px;
    padding: 32px;
    white-space: nowrap;
    word-break: keep-all;
  }
}

@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 32px;
    white-space: nowrap;
    word-break: keep-all;
  }
}

.clickable {
  cursor: pointer;
}